html, body {
  width: 100%;
  height: 100%;
  background-color: black;
}

.App {
  height: 100%;
  width: 100%;
  max-width: 1980px;
  position: relative;
  margin: 0 auto;
}